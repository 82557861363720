import { Avatar, Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getSubscriptionInfo } from "../api";
import useMe from "../lib/useMe";
import SubscribeButton from "./SubscribeButton";
import { seperateNumber } from "../lib/functions";

export default function UserPageHeader({ user }) {
  const { isMeLoading, isAuthenticated, me } = useMe();
  const { isLoading, data } = useQuery(
    ["subscriptionInfo", user.nickname],
    getSubscriptionInfo
  );
  return (
    <HStack width="95%" spacing="10px" display="flex" justifyContent="center">
      <Box>
        <Avatar
          name={user.nickname}
          size="lg"
          src={user.avatar}
          backgroundColor="gray"
          textColor="white"
        />
      </Box>
      <VStack maxWidth="40%">
        <Text
          fontSize="lg"
          as="b"
          maxWidth="100%"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {user.nickname}
        </Text>
        <Text>
          구독자 {!isLoading ? seperateNumber(data?.totalSubscribers) : "-"}명
        </Text>
      </VStack>
      {!isMeLoading && (!isAuthenticated || user.nickname !== me.nickname) ? (
        <SubscribeButton nickname={user.nickname} buttonSize="lg" />
      ) : null}
    </HStack>
  );
}
