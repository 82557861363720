import Cookie from "js-cookie";
import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.younote.net/api/v1/",
  withCredentials: true,
});

export async function getMe() {
  const response = await instance.get("users/me/");
  return response.data;
}

export async function validateUsername({ username }) {
  const response = await instance.post(
    "users/validate-username/",
    { username },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
  return response.data;
}

export async function validateNickname({ nickname }) {
  const response = await instance.post(
    "users/validate-nickname/",
    { nickname },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
  return response.data;
}

export async function signUp(data) {
  const response = await instance.post("users/sign-up/", data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function login({ username, password }) {
  const response = await instance.post(
    "users/login/",
    { username, password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
  return response.data;
}

export async function signOut() {
  const response = await instance.post("users/sign-out/", null, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function getUploadURL() {
  const response = await instance.post("/users/upload-url/", null, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function uploadImage({ file, uploadURL }) {
  const form = new FormData();
  form.append("file", file);
  const response = await axios.post(uploadURL, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function updateMyInfo(data) {
  const response = await instance.put("users/update-my-info/", data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function writePost(data) {
  const response = await instance.post("posts/write/", data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function editPost({ postPk, data }) {
  const response = await instance.put(`posts/${postPk}/`, data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function deletePost(postPk) {
  const response = await instance.delete(`posts/${postPk}/`, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function getNotices() {
  const response = await instance.get("posts/notices/");
  return response.data;
}

export async function getAllPosts({ queryKey: [_, page] }) {
  const response = await instance.get(`posts/all/?page=${page || 1}`);
  return response.data;
}

export async function getSubscriptionPosts({ queryKey: [_, page] }) {
  const response = await instance.get(`posts/subscriptions/?page=${page || 1}`);
  return response.data;
}

export async function getRecommendedPosts({ queryKey: [_, page] }) {
  const response = await instance.get(`posts/recommended/?page=${page || 1}`);
  return response.data;
}

export async function getPostDetail({ queryKey }) {
  const postPk = queryKey[1];
  const response = await instance.get(`posts/${postPk}/`);
  return response.data;
}

export async function getPostLike({ queryKey }) {
  const postPk = queryKey[1];
  const response = await instance.get(`posts/${postPk}/like/`);
  return response.data;
}

export async function getPostDislike({ queryKey }) {
  const postPk = queryKey[1];
  const response = await instance.get(`posts/${postPk}/dislike/`);
  return response.data;
}

export async function likePost(postPk) {
  const response = await instance.put(`posts/${postPk}/like/`, null, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function dislikePost(postPk) {
  const response = await instance.put(`posts/${postPk}/dislike/`, null, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function getUserPageInfo({ queryKey: [_, nickname] }) {
  const response = await instance.get(`users/@${nickname}/info/`);
  return response.data;
}

export async function getUserPosts({ queryKey: [_, nickname, page] }) {
  const response = await instance.get(
    `users/@${nickname}/posts/?page=${page || 1}`
  );
  return response.data;
}

export async function getSubscriptionInfo({ queryKey: [_, nickname] }) {
  const response = await instance.get(`users/subscribe/@${nickname}/`);
  return response.data;
}

export async function toggleSubscribe(nickname) {
  const response = await instance.put(`users/subscribe/@${nickname}/`, null, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}

export async function getTotalEarnings() {
  const response = await instance.get("earnings/total-earnings/");
  return response.data;
}

export async function getTotalApprovedSettlements() {
  const response = await instance.get("earnings/total-approved-settlements/");
  return response.data;
}

export async function getSettlementsInProgress() {
  const response = await instance.get("earnings/settlements-in-progress/");
  return response.data;
}

export async function getEarningsDetails({ queryKey: [_, page] }) {
  const response = await instance.get(
    `earnings/earnings-details/?page=${page || 1}`
  );
  return response.data;
}

export async function getSettlementsDetails({ queryKey: [_, page] }) {
  const response = await instance.get(
    `earnings/settlements-details/?page=${page || 1}`
  );
  return response.data;
}

export async function requestSettlement(amount) {
  const response = await instance.post(
    "earnings/settlement-request/",
    { amount },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );
  return response.data;
}

export async function getAccountInfo() {
  const response = await instance.get("earnings/account/");
  return response.data;
}

export async function updateAccount(data) {
  const response = await instance.put("earnings/account/", data, {
    headers: {
      "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
  });
  return response.data;
}
