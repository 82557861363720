import {
  Box,
  Button,
  Divider,
  HStack,
  Skeleton,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";

export default function SkeletonPostDetail() {
  return (
    <VStack maxWidth="780px" width="100%" marginY="10px" spacing="10px">
      <Skeleton width="50%" height="30px" />
      <VStack
        width="100%"
        display="block"
        justifyContent="right"
        paddingX="10px"
      >
        <Box width="100%" display="flex" justifyContent="right">
          <Skeleton width="40%" height="30px" />
        </Box>
        <Box width="100%" display="flex" justifyContent="right">
          <Skeleton width="30%" height="20px" />
        </Box>
        <Box width="100%" display="flex" justifyContent="right">
          <Skeleton width="20%" height="20px" />
        </Box>
      </VStack>
      <Divider backgroundColor="black" height="1px" />
      <Box paddingY="60px">
        <Spinner size="xl" />
      </Box>
      <HStack spacing="20px" paddingY="40px">
        <Button
          width="68px"
          height="40px"
          variant="solid"
          colorScheme="blue"
          color="white"
        >
          <HStack spacing="10px">
            <AiFillLike color="white" />
            <Text>0</Text>
          </HStack>
        </Button>
        <Button
          width="68px"
          height="40px"
          variant="solid"
          colorScheme="blackAlpha"
          color="white"
        >
          <HStack spacing="10px">
            <AiFillDislike color="white" />
            <Text>0</Text>
          </HStack>
        </Button>
      </HStack>
      <Divider backgroundColor="black" height="1px" />
      <Box
        paddingRight="10px"
        width="100%"
        display="flex"
        justifyContent="right"
      >
        <Button width="78px" height="40px" colorScheme="blue">
          글쓰기
        </Button>
      </Box>
    </VStack>
  );
}
