import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { dislikePost, getPostDislike, getPostLike, likePost } from "../api";
import ProtectedButton from "./ProtectedButton";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UserProfile from "./UserProfile";
import SubscribeButton from "./SubscribeButton";
import { useNavigate, useParams } from "react-router-dom";
import DeletePostAlert from "./DeletePostAlert";
import usePage from "../lib/usePage";
import { seperateNumber } from "../lib/functions";
import "../styles/content-styles.css";

export default function PostDetail({
  postPk,
  isAuthor,
  isStaff,
  title,
  createdTimeString,
  body,
  author,
  totalViews,
}) {
  const { nickname } = useParams();
  const createdDate = `${createdTimeString.slice(0, 10)}`;
  const createdTime = `${createdTimeString.slice(11, 19)}`;
  const createdAt = `${createdDate} ${createdTime}`;
  const page = usePage();
  const { isLoading: isLikeLoading, data: like } = useQuery(
    ["postLike", postPk],
    getPostLike
  );
  const { isLoading: isDislikeLoading, data: dislike } = useQuery(
    ["postDislike", postPk],
    getPostDislike
  );
  const queryClient = useQueryClient();
  const likeMutation = useMutation(likePost, {
    onSuccess: () => {
      queryClient.refetchQueries(["postLike", postPk]);
      queryClient.refetchQueries(["postDislike", postPk]);
    },
  });
  const dislikeMutation = useMutation(dislikePost, {
    onSuccess: () => {
      queryClient.refetchQueries(["postLike", postPk]);
      queryClient.refetchQueries(["postDislike", postPk]);
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <VStack maxWidth="780px" width="100%" marginY="10px" spacing="10px">
      <Text width="95%" textAlign="center" fontSize="2xl" as="b">
        {title}
      </Text>
      <VStack
        width="100%"
        display="block"
        justifyContent="right"
        paddingX="10px"
      >
        <HStack width="100%" justifyContent="right" spacing="10px">
          <UserProfile user={author} fontSize="lg" />
          {isAuthor ? null : (
            <SubscribeButton nickname={author.nickname} buttonSize="sm" />
          )}
        </HStack>
        <VStack marginY="2px">
          <Text width="100%" textAlign="right" fontSize="sm">
            {`조회 ${seperateNumber(totalViews || 0)} · 추천 ${
              like?.totalLikes > 0 ? "+" : ""
            }${seperateNumber(like?.totalLikes || 0)}
             · 비추천 ${dislike?.totalDislikes > 0 ? "-" : ""}${seperateNumber(
              dislike?.totalDislikes || 0
            )}`}
          </Text>
          <Text width="100%" textAlign="right" fontSize="sm" color="gray">
            {createdAt}
          </Text>
        </VStack>
      </VStack>
      <Divider backgroundColor="black" height="1px" />
      <Box
        maxWidth="780px"
        width="95%"
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <HStack spacing="20px" paddingY="40px">
        {!isLikeLoading ? (
          <ProtectedButton>
            <Button
              width="68px"
              height="40px"
              variant={like?.isLiked ? "outline" : "solid"}
              colorScheme={like?.isLiked ? "whiteAlpha" : "blue"}
              color={like?.isLiked ? "black" : "white"}
              onClick={() => {
                if (!isAuthor) {
                  likeMutation.mutate(postPk);
                }
              }}
            >
              <HStack spacing="10px">
                <AiFillLike color={like?.isLiked ? "black" : "white"} />
                <Text>{like?.totalLikes}</Text>
              </HStack>
            </Button>
          </ProtectedButton>
        ) : null}
        {!isDislikeLoading ? (
          <ProtectedButton>
            <Button
              width="68px"
              height="40px"
              variant={dislike?.isDisliked ? "outline" : "solid"}
              colorScheme={dislike?.isDisliked ? "whiteAlpha" : "blackAlpha"}
              color={dislike?.isDisliked ? "black" : "white"}
              onClick={() => {
                if (!isAuthor) {
                  dislikeMutation.mutate(postPk);
                }
              }}
            >
              <HStack spacing="10px">
                <AiFillDislike
                  color={dislike?.isDisliked ? "black" : "white"}
                />
                <Text>{dislike?.totalDislikes}</Text>
              </HStack>
            </Button>
          </ProtectedButton>
        ) : null}
      </HStack>
      <Divider backgroundColor="black" height="1px" />
      <Box
        paddingRight="10px"
        width="100%"
        display="flex"
        justifyContent="right"
      >
        <HStack spacing="10px">
          {isAuthor ? (
            <>
              <Button
                width="68px"
                height="40px"
                colorScheme="blackAlpha"
                onClick={() => {
                  if (nickname) {
                    navigate(
                      `/users/${nickname}/posts/${postPk}/edit?page=${page}`
                    );
                  } else {
                    navigate(`/posts/${postPk}/edit?page=${page}`);
                  }
                }}
              >
                수정
              </Button>
            </>
          ) : null}
          {isAuthor || isStaff ? (
            <>
              <Button
                width="68px"
                height="40px"
                colorScheme="blackAlpha"
                onClick={onOpen}
              >
                삭제
              </Button>
              <DeletePostAlert
                postPk={postPk}
                nickname={author.nickname}
                isOpen={isOpen}
                onClose={onClose}
              />
            </>
          ) : null}
          <ProtectedButton>
            <Button
              width="78px"
              height="40px"
              colorScheme="blue"
              onClick={() => {
                if (nickname) {
                  navigate(`/users/${nickname}/posts/write?page=${page}`);
                  window.scrollTo(0, 0);
                } else {
                  navigate(`/posts/write?page=${page}`);
                  window.scrollTo(0, 0);
                }
              }}
            >
              글쓰기
            </Button>
          </ProtectedButton>
        </HStack>
      </Box>
    </VStack>
  );
}
