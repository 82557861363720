import { VStack } from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";
import UserPosts from "../components/UserPosts";

export default function UserPostsPage() {
  const { nickname } = useParams();
  return (
    <VStack width="100%" spacing="10px">
      <Outlet />
      <UserPosts nickname={nickname} />
    </VStack>
  );
}
