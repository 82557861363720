export function getPageArray(page) {
  const base = page - ((page - 1) % 10) - 1;
  return Array.from(Array(10).keys()).map((num) => base + num + 1);
}

export function seperateNumber(number) {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
}

export function shuffled(array) {
  const order = [
    6, 17, 15, 3, 12, 10, 7, 18, 2, 9, 16, 1, 13, 0, 19, 4, 5, 11, 8, 14,
  ];
  return order.map((e) => array[e]);
}
