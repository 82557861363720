import { VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PostDetail from "../components/PostDetail";
import usePost from "../lib/usePost";
import SkeletonPostDetail from "../components/SkeletonPostDetail";

export default function Post() {
  const { postPk } = useParams();
  const { isLoading, isAuthor, isStaff, post } = usePost(postPk);
  return (
    <>
      {!isLoading ? (
        <VStack width="100%" spacing="10px">
          <PostDetail
            key={post.pk}
            postPk={post.pk}
            isAuthor={isAuthor}
            isStaff={isStaff}
            title={post.title}
            createdTimeString={post.createdAt}
            body={post.body}
            author={post.author}
            totalViews={post.totalViews}
          />
        </VStack>
      ) : (
        <SkeletonPostDetail />
      )}
    </>
  );
}
