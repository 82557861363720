import { useQuery } from "@tanstack/react-query";
import { getMe } from "../api";

export default function useMe() {
  const { isLoading, data } = useQuery(["me"], getMe, { retry: false });
  return {
    isMeLoading: isLoading,
    isAuthenticated: data?.isAuthenticated,
    me: data?.me,
  };
}
