import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  HStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { deletePost } from "../api";
import usePage from "../lib/usePage";

export default function DeletePostAlert({ postPk, nickname, isOpen, onClose }) {
  const { nickname: fromPrivate } = useParams();
  const page = usePage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deleteMutation = useMutation(deletePost, {
    onSuccess: () => {
      if (fromPrivate) {
        queryClient.refetchQueries(["userPosts", nickname, page]);
        navigate(`/users/${nickname}/posts?page=${page}`);
      } else {
        queryClient.refetchQueries(["allPosts", page]);
        navigate(`/posts?page=${page}`);
      }
    },
  });
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent width="90%">
          <AlertDialogHeader>게시물 삭제</AlertDialogHeader>
          <AlertDialogBody>
            게시물을 삭제하시겠습니까? 삭제된 게시물은 복구할 수 없습니다.
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack spacing="10px">
              <Button
                width="64px"
                height="36px"
                colorScheme="blackAlpha"
                onClick={() => {
                  deleteMutation.mutate(postPk);
                }}
              >
                삭제
              </Button>
              <Button
                width="64px"
                height="36px"
                colorScheme="blue"
                onClick={onClose}
              >
                취소
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
