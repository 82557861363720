import { Box, Link, Text, VStack, Button, Image } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllPosts, getNotices, getRecommendedPosts } from "../api";
import PostList from "../components/PostList";
import { useNavigate } from "react-router-dom";
import SkeletonPostList from "../components/SkeletonPostList";

export default function Home() {
  const { isLoading: isRecommendedPostsLoading, data: recommendedPosts } =
    useQuery(["recommendedPosts"], getRecommendedPosts);
  const { isLoading: isNoticesLoading, data: notices } = useQuery(
    ["notices"],
    getNotices
  );
  const { isLoading: isAllPostsLoading, data: allPosts } = useQuery(
    ["allPosts"],
    getAllPosts
  );
  const navigate = useNavigate();
  return (
    <VStack width="100%" spacing="20px">
      <VStack width="100%">
        <Link href="/posts/recommended">
          <Text fontSize="2xl" as="b">
            추천 게시물
          </Text>
        </Link>
        {!isRecommendedPostsLoading && recommendedPosts ? (
          <VStack width="100%" spacing="10px">
            <PostList posts={recommendedPosts} path="/posts/recommended" />
            <Box
              paddingRight="10px"
              width="100%"
              display="flex"
              justifyContent="right"
            >
              <Button
                width="78px"
                height="40px"
                colorScheme="blue"
                onClick={() => {
                  navigate("/posts/recommended");
                  window.scrollTo(0, 0);
                }}
              >
                더보기
              </Button>
            </Box>
          </VStack>
        ) : (
          <SkeletonPostList length={20} />
        )}
      </VStack>
      <VStack width="100%">
        <Link href="/users/YouNote운영자/posts">
          <Text fontSize="2xl" as="b">
            공지사항
          </Text>
        </Link>
        {!isNoticesLoading ? (
          <VStack width="100%" spacing="10px">
            <PostList
              posts={notices.slice(0, 3)}
              path="/users/YouNote운영자/posts"
            />
            <Box
              paddingRight="10px"
              width="100%"
              display="flex"
              justifyContent="right"
            >
              <Button
                width="78px"
                height="40px"
                colorScheme="blue"
                onClick={() => {
                  navigate("/users/YouNote운영자/posts");
                  window.scrollTo(0, 0);
                }}
              >
                더보기
              </Button>
            </Box>
          </VStack>
        ) : (
          <SkeletonPostList length={3} />
        )}
      </VStack>
      <VStack width="100%">
        <Link href="/posts">
          <Text fontSize="2xl" as="b">
            전체 게시물
          </Text>
        </Link>
        {!isAllPostsLoading ? (
          <VStack width="100%" spacing="10px">
            <PostList posts={allPosts.slice(0, 3)} path="/posts" />
            <Box
              paddingRight="10px"
              width="100%"
              display="flex"
              justifyContent="right"
            >
              <Button
                width="78px"
                height="40px"
                colorScheme="blue"
                onClick={() => {
                  navigate("/posts");
                  window.scrollTo(0, 0);
                }}
              >
                더보기
              </Button>
            </Box>
          </VStack>
        ) : (
          <SkeletonPostList length={3} />
        )}
      </VStack>
    </VStack>
  );
}
