import { Box, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MyAds from "../components/MyAds";

export default function Root() {
  return (
    <Box>
      <VStack
        width="100%"
        spacing="10px"
        display="flex"
        justifyContent="center"
      >
        <Header />
        <VStack
          maxWidth="720px"
          width="100%"
          spacing="20px"
          minHeight="110dvh"
          display="flex"
          justifyContent="center"
        >
          { /* <MyAds /> */ }
          <Outlet />
        </VStack>
        <Footer />
      </VStack>
      <ReactQueryDevtools />
    </Box>
  );
}
