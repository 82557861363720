import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getEarningsDetails } from "../api";
import { seperateNumber } from "../lib/functions";
import usePage from "../lib/usePage";
import PageIndex from "../components/PageIndex";

export default function EarningsDetails() {
  const page = usePage();

  const { isLoading, data: earningsDetails } = useQuery(
    ["earningsDetails", page],
    getEarningsDetails
  );

  return (
    <VStack maxWidth="700px" width="95%" marginTop="10px">
      {!isLoading ? (
        earningsDetails?.length ? (
          <VStack width="100%" spacing="0px">
            <Divider backgroundColor="black" height="1px" />
            <HStack width="100%" height="30px" spacing="0">
              <Text width="25%" fontSize="sm" as="b">
                날짜
              </Text>
              <Text width="55%" fontSize="sm" as="b">
                게시물
              </Text>
              <Text width="20%" fontSize="sm" as="b">
                금액
              </Text>
            </HStack>
            <Divider backgroundColor="black" height="1px" />
            {earningsDetails.map(({ date, postTitle, amount }, key) => (
              <HStack
                key={key}
                width="100%"
                height="30px"
                spacing="0"
                borderBottomWidth="1px"
              >
                <Text width="25%" fontSize="xs">
                  {date}
                </Text>
                <Text
                  width="55%"
                  fontSize="xs"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textAlign="left"
                >
                  {postTitle}
                </Text>
                <Text width="20%" fontSize="xs">
                  ₩{seperateNumber(amount)}
                </Text>
              </HStack>
            ))}
          </VStack>
        ) : (
          <Box
            width="100%"
            height="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderWidth="2px"
            borderColor="gray.600"
            borderRadius="2xl"
          >
            <Text fontSize="lg" as="b">
              수익 내역이 없습니다.
            </Text>
          </Box>
        )
      ) : null}
      <PageIndex preventScroll={true} />
    </VStack>
  );
}
