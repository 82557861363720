import { HStack, VStack, Link, Text, Avatar, Image } from "@chakra-ui/react";
import usePage from "../lib/usePage";
import { seperateNumber } from "../lib/functions";
import { useState } from "react";

export default function PostOverview({
  pk,
  thumbnail,
  title,
  author: { nickname, avatar },
  createdTimeString,
  totalViews,
  totalLikes,
  totalDislikes,
  isRead,
  path,
  currentPostPk,
  now,
}) {
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const createdAt = new Date(createdTimeString);
  const timeString =
    now - createdAt >= ONE_DAY
      ? `${createdAt.getMonth() + 1}/${createdAt.getDate()}`
      : `${createdAt.getHours()}`.padStart(2, "0") +
        ":" +
        `${createdAt.getMinutes()}`.padStart(2, "0");
  const netLikes = totalLikes - totalDislikes;
  const page = usePage();
  const [href, setHref] = useState(`${path}/${pk}?page=${page}`);
  return (
    <Link
      width="100%"
      href={href}
      color={isRead ? "gray" : "black"}
      style={{ textDecoration: "none" }}
    >
      <HStack
        width="100%"
        backgroundColor={pk === currentPostPk ? "blue.50" : null}
      >
        <Image
          boxSize="64px"
          src={
            thumbnail ||
            "https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/0bdb0c94-14c8-40b8-be09-9b61e15d1900/crop"
          }
        />
        <VStack width="80%" spacing="0" display="block" alignItems="center">
          <Text
            maxWidth="100%"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="md"
          >
            {title}
          </Text>
          <HStack spacing="5px">
            <Avatar
              name={nickname}
              size="xs"
              src={avatar}
              backgroundColor="gray"
              textColor="white"
              onMouseOver={() => {
                setHref(`/users/${nickname}`);
              }}
              onMouseLeave={() => {
                setHref(`${path}/${pk}?page=${page}`);
              }}
            />
            <Text
              fontSize="11px"
              maxWidth="100%"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {nickname}
            </Text>
            <Text fontSize="11px">·</Text>
            <Text fontSize="11px">{timeString}</Text>
            <Text fontSize="11px">·</Text>
            <Text fontSize="11px">조회 {seperateNumber(totalViews)}</Text>
            <Text fontSize="11px">·</Text>
            <Text fontSize="11px">
              추천 {netLikes > 0 ? "+" : null}
              {seperateNumber(netLikes)}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Link>
  );
}
