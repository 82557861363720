import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMe from "../lib/useMe";

export default function ProtectedPage({ children }) {
  const { isMeLoading, isAuthenticated } = useMe();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isMeLoading && !isAuthenticated) {
      navigate("/");
    }
  }, [isMeLoading, isAuthenticated, navigate]);
  return <>{children}</>;
}
