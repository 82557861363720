import {
  Box,
  Button,
  FormControl,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import uploadAdapterPlugin from "../lib/uploadAdapterPlugin";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPostDetail, getUploadURL, uploadImage } from "../api";
import ProtectedPage from "../components/ProtectedPage";
import useMe from "../lib/useMe";
import { useMutation } from "@tanstack/react-query";
import { editPost } from "../api";
import usePage from "../lib/usePage";

export default function EditPost() {
  const { nickname } = useParams();
  const page = usePage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [thumbnail, setThumbnail] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { postPk } = useParams();
  const { isPostLoading, data: post } = useQuery(
    ["post", postPk],
    getPostDetail
  );
  const { isMeLoading, isAuthenticated, me } = useMe();
  useEffect(() => {
    if (!isPostLoading) {
      setThumbnail(post?.thumbnail || "");
      setTitle(post?.title || "");
      setBody(post?.body || "");
      if (
        !isMeLoading &&
        !(isAuthenticated && post.author.nickname === me.nickname)
      ) {
        navigate("/");
      }
    }
  }, [isPostLoading, post, isMeLoading, isAuthenticated, me, navigate]);
  const editPostMutation = useMutation(editPost, {
    onSuccess: () => {
      if (nickname) {
        queryClient.refetchQueries(["userPosts", nickname, page]);
        navigate(`/users/${nickname}/posts/${postPk}?page=${page}`);
        window.scrollTo(0, 0);
      } else {
        queryClient.refetchQueries(["allPosts", page]);
        navigate(`/posts/${postPk}?page=${page}`);
        window.scrollTo(0, 0);
      }
    },
  });
  return (
    <VStack width="100%">
      {!isPostLoading && !isMeLoading ? (
        isAuthenticated && post.author.nickname === me.nickname ? (
          <ProtectedPage>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              marginY="20px"
            >
              <VStack maxWidth="780px" width="95%">
                <FormControl>
                  <HStack spacing="10px">
                    <Image
                      boxSize="64px"
                      borderColor="gray"
                      src={
                        thumbnail ||
                        "https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/0bdb0c94-14c8-40b8-be09-9b61e15d1900/crop"
                      }
                    />
                    <Button
                      width="60px"
                      height="36px"
                      colorScheme="blue"
                      isLoading={isThumbnailUploading}
                      onClick={() => {
                        document.getElementById("selectThumbnail").click();
                      }}
                    >
                      <Text fontSize="sm">업로드</Text>
                    </Button>
                    <Button
                      widht="48px"
                      height="36px"
                      colorScheme="blackAlpha"
                      isDisabled={!thumbnail | isThumbnailUploading}
                      onClick={() => {
                        setThumbnail("");
                      }}
                    >
                      <Text fontSize="sm">삭제</Text>
                    </Button>
                    <Input
                      id="selectThumbnail"
                      width="0"
                      visibility="hidden"
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        getUploadURL().then(({ uploadURL }) => {
                          setIsThumbnailUploading(true);
                          uploadImage({
                            file: event.target.files[0],
                            uploadURL,
                          })
                            .then(({ result }) => {
                              setThumbnail(
                                `https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/${result.id}/crop`
                              );
                            })
                            .then(() => {
                              setIsThumbnailUploading(false);
                            });
                        });
                      }}
                    />
                  </HStack>
                </FormControl>
                <FormControl>
                  <Input
                    maxLength="150"
                    placeholder="제목을 입력해주세요."
                    required
                    type="text"
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    value={title}
                  />
                </FormControl>
                <Box width="100%" marginY="20px">
                  <CKEditor
                    data={post?.body}
                    editor={Editor}
                    config={{
                      extraPlugins: [uploadAdapterPlugin],
                      removePlugins: ["MediaEmbedToolbar"],
                      mediaEmbed: {
                        previewsInData: true,
                      },
                      functions: {
                        beforeUpload: () => {
                          setIsImageUploading(true);
                        },
                        afterUpload: () => {
                          setIsImageUploading(false);
                        },
                      },
                    }}
                    onChange={(_, editor) => {
                      setBody(editor.getData());
                    }}
                  />
                </Box>
                <Box width="100%" display="flex" justifyContent="right">
                  <HStack spacing="10px">
                    <Button
                      width="68px"
                      height="40px"
                      colorScheme="blue"
                      isDisabled={!title}
                      isLoading={
                        isThumbnailUploading ||
                        isImageUploading ||
                        editPostMutation.isLoading
                      }
                      onClick={() => {
                        editPostMutation.mutate({
                          postPk,
                          data: { thumbnail, title, body },
                        });
                      }}
                    >
                      수정
                    </Button>
                    <Button
                      width="68px"
                      height="40px"
                      colorScheme="blackAlpha"
                      onClick={() => navigate(-1)}
                    >
                      취소
                    </Button>
                  </HStack>
                </Box>
              </VStack>
            </Box>
          </ProtectedPage>
        ) : null
      ) : null}
    </VStack>
  );
}
