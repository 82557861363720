import { Box, Button, Link, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getUserPosts } from "../api";
import useMe from "../lib/useMe";
import usePage from "../lib/usePage";
import PageIndex from "./PageIndex";
import PostList from "./PostList";
import SkeletonPostList from "./SkeletonPostList";

export default function UserPosts({ nickname }) {
  const { isMeLoading, isAuthenticated, me } = useMe();
  const page = usePage();
  const { isLoading, data: userPosts } = useQuery(
    ["userPosts", nickname, page],
    getUserPosts
  );
  const navigate = useNavigate();
  return (
    <VStack width="100%" spacing="10px">
      <VStack width="100%">
        <Link href={`/users/${nickname}/posts`}>
          <Text fontSize="2xl" as="b">
            {nickname}의 게시물
          </Text>
        </Link>
        {!isLoading ? (
          <PostList posts={userPosts} path={`/users/${nickname}/posts`} />
        ) : (
          <SkeletonPostList />
        )}
      </VStack>
      {!isMeLoading && isAuthenticated && nickname === me?.nickname ? (
        <Box
          paddingRight="10px"
          width="100%"
          display="flex"
          justifyContent="right"
        >
          <Button
            width="78px"
            height="40px"
            colorScheme="blue"
            onClick={() => {
              navigate(`/users/${nickname}/posts/write?page=${page}`);
              window.scrollTo(0, 0);
            }}
          >
            글쓰기
          </Button>
        </Box>
      ) : null}
      <Box>
        <PageIndex />
      </Box>
    </VStack>
  );
}
