import { Box, Divider, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PostOverview from "./PostOverview";
import InFeed from "./AdSense/InFeed";

export default function PostList({ posts, path }) {
  const { postPk } = useParams();
  const now = new Date();
  return (
    <Box width="100%" display="flex" justifyContent="center">
      {posts?.length ? (
        <VStack width="100%" spacing="0px">
          <Divider backgroundColor="black" height="1px" />
          {posts.slice(0, 10).map((post, index) => (
            <VStack key={post.pk} width="100%" spacing="0px">
              <PostOverview
                pk={post.pk}
                thumbnail={post.thumbnail}
                title={post.title}
                author={post.author}
                createdTimeString={post.createdAt}
                totalViews={post.totalViews}
                totalLikes={post.totalLikes}
                totalDislikes={post.totalDislikes}
                isRead={post.isRead}
                path={path}
                currentPostPk={parseInt(postPk)}
                now={now}
              />
              {index === posts.length - 1 ? (
                <Divider backgroundColor="black" height="1px" />
              ) : (
                <Divider />
              )}
            </VStack>
          ))}
          {posts.length > 10 ? (
            <VStack width="100%" spacing="0px">
              <Box width="100%">
                {/* <InFeed /> */}
              </Box>
              <Divider />
            </VStack>
          ) : null}
          {posts.slice(10, posts.length).map((post, index) => (
            <VStack key={post.pk} width="100%" spacing="0px">
              <PostOverview
                pk={post.pk}
                thumbnail={post.thumbnail}
                title={post.title}
                author={post.author}
                createdTimeString={post.createdAt}
                totalViews={post.totalViews}
                totalLikes={post.totalLikes}
                totalDislikes={post.totalDislikes}
                isRead={post.isRead}
                path={path}
                currentPostPk={parseInt(postPk)}
                now={now}
              />
              {posts.length < 20 && index === posts.length - 1 ? (
                <Divider backgroundColor="black" height="1px" />
              ) : (
                <Divider />
              )}
            </VStack>
          ))}
          {posts.length === 20 ? (
            <VStack width="100%" spacing="0px">
              <Box width="100%">
                {/* <InFeed /> */}
              </Box>
              <Divider backgroundColor="black" height="1px" />
            </VStack>
          ) : null}
        </VStack>
      ) : (
        <Box
          width="95%"
          height="130px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderColor="black"
          borderWidth="2px"
          borderRadius="2xl"
        >
          <Text fontSize="lg" as="b">
            게시물이 없습니다.
          </Text>
        </Box>
      )}
    </Box>
  );
}
