import { Button } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getSubscriptionInfo, toggleSubscribe } from "../api";
import ProtectedButton from "./ProtectedButton";

export default function SubscribeButton({ nickname, buttonSize }) {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    ["subscriptionInfo", nickname],
    getSubscriptionInfo
  );
  const toggleSubscribeMutation = useMutation(toggleSubscribe, {
    onSuccess: () => {
      queryClient.refetchQueries(["subscriptionInfo"]);
    },
  });
  const width =
    buttonSize === "lg" ? "96px" : buttonSize === "sm" ? "60px" : null;
  const height =
    buttonSize === "lg" ? "44px" : buttonSize === "sm" ? "32px" : null;
  return (
    <ProtectedButton>
      {!isLoading ? (
        <Button
          width={width}
          height={height}
          fontSize={buttonSize}
          variant={data?.isSubscribed ? "outline" : "solid"}
          colorScheme={data?.isSubscribed ? "whiteAlpha" : "blue"}
          color={data?.isSubscribed ? "black" : "white"}
          onClick={() => {
            toggleSubscribeMutation.mutate(nickname);
          }}
        >
          {data?.isSubscribed ? "구독중" : "구독"}
        </Button>
      ) : (
        <></>
      )}
    </ProtectedButton>
  );
}
