import { VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import RecommendedPosts from "../components/RecommendedPosts";

export default function RecommendedPostsPage() {
  return (
    <VStack width="100%" spacing="10px">
      <Outlet />
      <RecommendedPosts />
    </VStack>
  );
}
