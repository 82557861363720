import { Box, Text } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Box
      width="100%"
      paddingY="20px"
      backgroundColor="blue.400"
      display="flex"
      justifyContent="center"
    >
      <Text color="white" fontSize="sm">
        Copyright ⓒ 2022-2023 YouNote. All rights reserved.
      </Text>
    </Box>
  );
}
