import { VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import ProtectedPage from "../components/ProtectedPage";
import SubscriptionPosts from "../components/SubscriptionPosts";

export default function SubscriptionPostsPage() {
  return (
    <ProtectedPage>
      <VStack width="100%" spacing="10px">
        <Outlet />
        <SubscriptionPosts />
      </VStack>
    </ProtectedPage>
  );
}
