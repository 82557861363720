import {
  Box,
  Button,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccountInfo, updateAccount } from "../api";
import EarningsSettlement from "../components/EarningsSettlement";
import ProtectedPage from "../components/ProtectedPage";
import EarningsDetails from "./EarningsDetails";
import SettlementsDetails from "./SettlementsDetails";

export default function Earnings() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const defaultIndex =
    pathname === "/earnings-settlement"
      ? 0
      : pathname === "/earnings-settlement/earnings-details"
      ? 1
      : pathname === "/earnings-settlement/settlements-details"
      ? 2
      : null;

  const [originalBank, setOriginalBank] = useState("");
  const [bank, setBank] = useState("");
  const [originalAccountNumber, setOriginalAccountNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [originalAccountHolder, setOriginalAccountHolder] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [isAccountInputValid, setIsAccountInputValid] = useState(false);
  const { isLoading: isAccountInfoLoading, data: accountInfo } = useQuery(
    ["accountInfo"],
    getAccountInfo
  );
  useEffect(() => {
    if (!isAccountInfoLoading && accountInfo.hasAccount) {
      const { bank, accountNumber, accountHolder } = accountInfo.account;
      setOriginalBank(bank);
      setBank(bank);
      setOriginalAccountNumber(accountNumber);
      setAccountNumber(accountNumber);
      setOriginalAccountHolder(accountHolder);
      setAccountHolder(accountHolder);
    }
  }, [isAccountInfoLoading, accountInfo]);
  useEffect(() => {
    if (!isAccountInfoLoading) {
      if (accountInfo.hasAccount) {
        setIsAccountInputValid(
          bank &&
            accountNumber &&
            accountHolder &&
            (bank !== originalBank ||
              accountNumber !== originalAccountNumber ||
              accountHolder !== originalAccountHolder)
        );
      } else {
        setIsAccountInputValid(bank && accountNumber && accountHolder);
      }
    }
  }, [
    isAccountInfoLoading,
    accountInfo,
    bank,
    originalBank,
    accountNumber,
    originalAccountNumber,
    accountHolder,
    originalAccountHolder,
  ]);
  const updateAccountMutation = useMutation(updateAccount, {
    onSuccess: () => {
      queryClient.refetchQueries(["accountInfo"]);
    },
  });
  const onAccountSubmit = () => {
    if (!isAccountInfoLoading) {
      const data = {
        ...(bank !== originalBank && { bank }),
        ...(accountNumber !== originalAccountNumber && { accountNumber }),
        ...(accountHolder !== originalAccountHolder && { accountHolder }),
      };
      updateAccountMutation.mutate(data);
    }
  };

  return (
    <ProtectedPage>
      <VStack maxWidth="780px" width="100%">
        {!isAccountInfoLoading ? (
          <Box
            width="90%"
            paddingY="20px"
            marginTop="30px"
            display="flex"
            justifyContent="center"
            borderWidth="1px"
            borderColor="gray.600"
          >
            <VStack spacing="10px" marginBottom="10px">
              <Text fontSize="2xl" as="b">
                내 계좌 정보
              </Text>
              <VStack>
                <Select
                  width="240px"
                  placeholder="- 은행 선택 -"
                  value={bank}
                  onChange={(event) => {
                    setBank(event.target.value);
                  }}
                >
                  {accountInfo.bankChoices.map(([code, bank], key) => (
                    <option key={key} value={code}>
                      {bank}
                    </option>
                  ))}
                </Select>
                <Input
                  width="240px"
                  type="text"
                  maxLength="30"
                  placeholder="계좌번호"
                  value={accountNumber}
                  onChange={(event) => {
                    setAccountNumber(event.target.value);
                  }}
                />
                <Input
                  width="240px"
                  type="text"
                  maxLength="20"
                  placeholder="예금주"
                  value={accountHolder}
                  onChange={(event) => {
                    setAccountHolder(event.target.value);
                  }}
                />
                <Box width="0x" />
                <Button
                  width="68px"
                  height="40px"
                  colorScheme="blue"
                  isDisabled={!isAccountInputValid}
                  isLoading={updateAccountMutation.isLoading}
                  onClick={onAccountSubmit}
                >
                  {accountInfo.hasAccount ? "수정" : "등록"}
                </Button>
              </VStack>
            </VStack>
          </Box>
        ) : null}
        <Box width="100%" paddingY="10px">
          <Tabs
            align="center"
            variant="unstyled"
            colorScheme="black"
            defaultIndex={defaultIndex}
          >
            <TabList>
              <Tab
                borderRadius="lg"
                _selected={{
                  color: "white",
                  background: "blue.500",
                }}
                onClick={() => {
                  navigate("");
                }}
              >
                <Text as="b">수익 정산</Text>
              </Tab>
              <Tab
                borderRadius="lg"
                _selected={{
                  color: "white",
                  background: "blue.500",
                }}
                onClick={() => {
                  navigate("");
                }}
              >
                <Text as="b">수익 내역</Text>
              </Tab>
              <Tab
                borderRadius="lg"
                isDisabled={!isAccountInfoLoading && !accountInfo?.hasAccount}
                _selected={{
                  color: "white",
                  background: "blue.500",
                }}
                onClick={() => {
                  navigate("");
                }}
              >
                <Text as="b">정산 내역</Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel width="100%" padding="0">
                {!isAccountInfoLoading ? (
                  <EarningsSettlement hasAccount={accountInfo?.hasAccount} />
                ) : null}
              </TabPanel>
              <TabPanel width="100%" padding="0">
                <EarningsDetails />
              </TabPanel>
              <TabPanel width="100%" padding="0">
                <SettlementsDetails />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </ProtectedPage>
  );
}
