import { createBrowserRouter } from "react-router-dom";
import Root from "./routes/Root";
import NotFound from "./routes/NotFound";
import Home from "./routes/Home";
import SignUp from "./routes/SignUp";
import EditMyInfo from "./routes/EditMyInfo";
import WritePost from "./routes/WritePost";
import Post from "./routes/Post";
import AllPostsPage from "./routes/AllPostsPage";
import SubscriptionPostsPage from "./routes/SubscriptionPostsPage";
import UserPage from "./routes/UserPage";
import UserPostsPage from "./routes/UserPostsPage";
import EditPost from "./routes/EditPost";
import Earnings from "./routes/Earnings";
import RecommendedPostsPage from "./routes/RecommendedPostsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "edit-my-info",
        element: <EditMyInfo />,
      },
      {
        path: "posts",
        element: <AllPostsPage />,
        children: [
          {
            path: ":postPk",
            element: <Post />,
          },
          {
            path: ":postPk/edit",
            element: <EditPost />,
          },
        ],
      },
      {
        path: "posts/write",
        element: <WritePost />,
      },
      {
        path: "posts/recommended",
        element: <RecommendedPostsPage />,
        children: [
          {
            path: ":postPk",
            element: <Post />,
          },
        ],
      },
      {
        path: "posts/subscriptions",
        element: <SubscriptionPostsPage />,
        children: [
          {
            path: ":postPk",
            element: <Post />,
          },
        ],
      },
      {
        path: "users/:nickname",
        element: <UserPage />,
      },
      {
        path: "users/:nickname/posts",
        element: <UserPostsPage />,
        children: [
          {
            path: ":postPk",
            element: <Post />,
          },
          {
            path: ":postPk/edit",
            element: <EditPost />,
          },
        ],
      },
      {
        path: "users/:nickname/posts/write",
        element: <WritePost />,
      },
      {
        path: "earnings-settlement",
        element: <Earnings />,
      },
    ],
  },
]);

export default router;
