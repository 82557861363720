import { Box, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import UserPageHeader from "../components/UserPageHeader";
import UserPosts from "../components/UserPosts";
import useUser from "../lib/useUser";

export default function UserPage() {
  const { nickname } = useParams();
  const { isLoading, isValid, data: user } = useUser(nickname);
  return (
    <>
      {!isLoading && isValid ? (
        <VStack width="100%">
          <Box
            width="95%"
            paddingY="30px"
            marginY="10px"
            display="flex"
            justifyContent="center"
            borderWidth="2px"
            borderColor="gray.600"
          >
            <UserPageHeader user={user} />
          </Box>
          <UserPosts nickname={user.nickname} />
        </VStack>
      ) : null}
    </>
  );
}
