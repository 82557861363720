import { getUploadURL, uploadImage } from "../api";

class UploadAdapter {
  constructor(loader, beforeUpload, afterUpload) {
    this.loader = loader;
    this.beforeUpload = beforeUpload;
    this.afterUpload = afterUpload;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve) => {
          this.beforeUpload();
          getUploadURL().then(({ uploadURL }) => {
            uploadImage({ file, uploadURL }).then(({ result }) => {
              resolve({
                default: `https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/${result.id}/public`,
              });
              this.afterUpload();
            });
          });
        })
    );
  }
}

export default function uploadAdapterPlugin(editor) {
  const beforeUpload = editor.config._config.functions.beforeUpload;
  const afterUpload = editor.config._config.functions.afterUpload;
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader, beforeUpload, afterUpload);
  };
}
