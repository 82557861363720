import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getSettlementsDetails } from "../api";
import usePage from "../lib/usePage";
import PageIndex from "../components/PageIndex";
import { seperateNumber } from "../lib/functions";

export default function SettlementsDetails() {
  const page = usePage();
  const { isLoading, data: settlementsDetails } = useQuery(
    ["settlementsDetails", page],
    getSettlementsDetails
  );

  return (
    <VStack maxWidth="700px" width="95%" marginTop="10px">
      {!isLoading ? (
        settlementsDetails?.length ? (
          <VStack width="100%" spacing="0px">
            <Divider backgroundColor="black" height="1px" />
            <HStack width="100%" height="30px" spacing="0">
              <Text width="28%" fontSize="sm" as="b">
                신청 일시
              </Text>
              <Text width="22%" fontSize="sm" as="b">
                금액
              </Text>
              <Text width="22%" fontSize="sm" as="b">
                처리 결과
              </Text>
              <Text width="28%" fontSize="sm" as="b">
                비고
              </Text>
            </HStack>
            <Divider backgroundColor="black" height="1px" />
            {settlementsDetails.map(
              ({ createdAt, amount, isApproved, remark }, key) => (
                <HStack
                  key={key}
                  width="100%"
                  height="30px"
                  spacing="0"
                  backgroundColor={
                    isApproved === null
                      ? null
                      : isApproved
                      ? "blue.50"
                      : "blackAlpha.100"
                  }
                  borderBottomWidth="1px"
                >
                  <Text width="28%" fontSize="xs">
                    {createdAt.slice(0, 10)} {createdAt.slice(11, 16)}
                  </Text>
                  <Text width="22%" fontSize="xs">
                    ₩{seperateNumber(amount)}
                  </Text>
                  <Text width="22%" fontSize="xs">
                    {isApproved === null
                      ? "처리 중"
                      : isApproved
                      ? "정산 완료"
                      : "거절됨"}
                  </Text>
                  <Text width="28%" fontSize="xs">
                    {remark || "-"}
                  </Text>
                </HStack>
              )
            )}
          </VStack>
        ) : (
          <Box
            width="100%"
            height="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderWidth="2px"
            borderColor="gray.600"
            borderRadius="2xl"
          >
            <Text fontSize="lg" as="b">
              정산 내역이 없습니다.
            </Text>
          </Box>
        )
      ) : null}
      <PageIndex preventScroll={true} />
    </VStack>
  );
}
