import { Box, Button, Link, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getAllPosts } from "../api";
import usePage from "../lib/usePage";
import PageIndex from "./PageIndex";
import PostList from "./PostList";
import ProtectedButton from "./ProtectedButton";
import SkeletonPostList from "./SkeletonPostList";

export default function AllPosts() {
  const page = usePage();
  const { isLoading, data } = useQuery(["allPosts", page], getAllPosts);
  const navigate = useNavigate();
  return (
    <VStack width="100%" spacing="10px">
      <VStack width="100%">
        <Link href="/posts">
          <Text fontSize="2xl" as="b">
            전체 게시물
          </Text>
        </Link>
        {!isLoading ? (
          <PostList posts={data} path="/posts" />
        ) : (
          <SkeletonPostList />
        )}
      </VStack>
      <Box
        paddingRight="10px"
        width="100%"
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        <ProtectedButton>
          <Button
            width="78px"
            height="40px"
            colorScheme="blue"
            onClick={() => {
              navigate(`/posts/write?page=${page}`);
              window.scrollTo(0, 0);
            }}
          >
            글쓰기
          </Button>
        </ProtectedButton>
      </Box>
      <Box>
        <PageIndex />
      </Box>
    </VStack>
  );
}
