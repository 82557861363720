import {
  HStack,
  Link,
  Image,
  Button,
  useDisclosure,
  Box,
  SkeletonCircle,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useMe from "../lib/useMe";
import { signOut } from "../api";
import LoginModal from "./LoginModal";
import MyMenu from "./MyMenu";

export default function Header() {
  const { isMeLoading, isAuthenticated, me } = useMe();
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const signOutMutation = useMutation(signOut, {
    onSuccess: () => {
      queryClient.refetchQueries(["me"]);
    },
  });
  const onSignOut = async () => {
    signOutMutation.mutate();
    queryClient.refetchQueries(["me"]);
  };
  return (
    <Box width="100%" height="90px" paddingX="40px" borderBottomWidth={1}>
      <HStack height="full" justifyContent="space-between">
        <Box>
          <Link href="/">
            <Image src="/images/logo.png" alt="logo" width={120} />
          </Link>
        </Box>
        <Box>
          {!isMeLoading ? (
            isAuthenticated ? (
              <MyMenu me={me} onSignOut={onSignOut} />
            ) : (
              <Button
                width="68px"
                height="40px"
                colorScheme="blue"
                onClick={onLoginOpen}
              >
                로그인
              </Button>
            )
          ) : (
            <SkeletonCircle size="12" />
          )}
        </Box>
      </HStack>
      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
    </Box>
  );
}
