import { useQuery } from "@tanstack/react-query";
import { getUserPageInfo } from "../api";

export default function useUser(nickname) {
  const { isLoading, data } = useQuery(
    ["userPageInfo", nickname],
    getUserPageInfo
  );
  return {
    isLoading,
    isValid: data?.isValid,
    data: data?.data,
  };
}
