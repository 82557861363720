import { VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import AllPosts from "../components/AllPosts";

export default function AllPostsPage() {
  return (
    <VStack width="100%" spacing="10px">
      <Outlet />
      <AllPosts />
    </VStack>
  );
}
