import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import useMe from "../lib/useMe";
import LoginModal from "./LoginModal";

export default function ProtectedButton({ children }) {
  const { isMeLoading, isAuthenticated } = useMe();
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  return (
    <>
      {!isMeLoading && !isAuthenticated ? (
        <>
          {React.cloneElement(children, { onClick: onLoginOpen, href: null })}
          <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
        </>
      ) : (
        children
      )}
    </>
  );
}
