import {
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  Text,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function MyMenu({ me, onSignOut }) {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton>
        <Avatar
          name={me?.nickname}
          size="md"
          src={me?.avatar}
          backgroundColor="gray"
          textColor="white"
        />
      </MenuButton>
      <MenuList paddingY="0px">
        <Box
          width="100%"
          height="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingY="10px"
          borderBottomWidth="1px"
        >
          <Text fontSize="lg" as="b">
            {me?.nickname}
          </Text>
        </Box>
        <MenuItem
          onClick={() => {
            navigate(`/users/${me?.nickname}/posts/write`);
            window.scrollTo(0, 0);
          }}
        >
          글쓰기
        </MenuItem>
        <MenuItem onClick={() => navigate("/posts")}>전체 게시물</MenuItem>
        <MenuItem onClick={() => navigate("/posts/subscriptions")}>
          구독 게시물
        </MenuItem>
        <MenuItem onClick={() => navigate(`/users/${me?.nickname}`)}>
          내 페이지
        </MenuItem>
        <MenuItem onClick={() => navigate("/edit-my-info")}>
          개인정보 수정
        </MenuItem>
        <MenuItem onClick={() => navigate("earnings-settlement")}>
          수익 정산
        </MenuItem>
        <MenuItem onClick={onSignOut}>로그아웃</MenuItem>
      </MenuList>
    </Menu>
  );
}
