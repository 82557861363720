import { useQuery } from "@tanstack/react-query";
import { getPostDetail } from "../api";
import useMe from "./useMe";

export default function usePost(postPk) {
  const { isLoading: isPostLoading, data: post } = useQuery(
    ["post", postPk],
    getPostDetail
  );
  const { isMeLoading, isAuthenticated, me } = useMe();
  return {
    isLoading: isPostLoading || isMeLoading,
    isAuthor:
      !isPostLoading &&
      !isMeLoading &&
      isAuthenticated &&
      post?.author.nickname === me?.nickname,
    isStaff: !isPostLoading && !isMeLoading && isAuthenticated && me?.isStaff,
    post,
  };
}
