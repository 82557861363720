import {
  Box,
  Button,
  FormControl,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import uploadAdapterPlugin from "../lib/uploadAdapterPlugin";
import ProtectedPage from "../components/ProtectedPage";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getUploadURL, uploadImage, writePost } from "../api";

export default function WritePost() {
  const { nickname } = useParams();
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const writePostMutation = useMutation(writePost, {
    onSuccess: ({ pk }) => {
      if (nickname) {
        navigate(`/users/${nickname}/posts/${pk}`);
        window.scrollTo(0, 0);
      } else {
        navigate(`/posts/${pk}`);
        window.scrollTo(0, 0);
      }
    },
  });
  return (
    <ProtectedPage>
      <Box width="100%" display="flex" justifyContent="center" marginY="20px">
        <VStack maxWidth="780px" width="95%">
          <FormControl>
            <HStack spacing="10px">
              <Image
                boxSize="68px"
                border="2px"
                borderColor="gray"
                src={
                  thumbnail ||
                  "https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/0bdb0c94-14c8-40b8-be09-9b61e15d1900/crop"
                }
              />
              <Button
                width="60px"
                height="36px"
                colorScheme="blue"
                isLoading={isThumbnailUploading}
                onClick={() => {
                  document.getElementById("selectThumbnail").click();
                }}
              >
                <Text fontSize="sm">업로드</Text>
              </Button>
              <Button
                widht="48px"
                height="36px"
                colorScheme="blackAlpha"
                isDisabled={!thumbnail | isThumbnailUploading}
                onClick={() => {
                  setThumbnail("");
                }}
              >
                <Text fontSize="sm">삭제</Text>
              </Button>
              <Input
                id="selectThumbnail"
                width="0"
                visibility="hidden"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  getUploadURL().then(({ uploadURL }) => {
                    setIsThumbnailUploading(true);
                    uploadImage({
                      file: event.target.files[0],
                      uploadURL,
                    })
                      .then(({ result }) => {
                        setThumbnail(
                          `https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/${result.id}/crop`
                        );
                      })
                      .then(() => {
                        setIsThumbnailUploading(false);
                      });
                  });
                }}
              />
            </HStack>
          </FormControl>
          <Input
            maxLength="150"
            placeholder="제목을 입력하세요."
            required
            type="text"
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            value={title}
          />
          <Box width="100%" marginY="20px">
            <CKEditor
              editor={Editor}
              config={{
                extraPlugins: [uploadAdapterPlugin],
                removePlugins: ["MediaEmbedToolbar"],
                mediaEmbed: {
                  previewsInData: true,
                },
                functions: {
                  beforeUpload: () => {
                    setIsImageUploading(true);
                  },
                  afterUpload: () => {
                    setIsImageUploading(false);
                  },
                },
              }}
              onChange={(_, editor) => {
                setBody(editor.getData());
              }}
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="right">
            <HStack spacing="10px">
              <Button
                width="68px"
                height="40px"
                colorScheme="blue"
                isDisabled={!title}
                isLoading={
                  isThumbnailUploading ||
                  isImageUploading ||
                  writePostMutation.isLoading
                }
                onClick={() => {
                  writePostMutation.mutate({ thumbnail, title, body });
                }}
              >
                확인
              </Button>
              <Button
                width="68px"
                height="40px"
                colorScheme="blackAlpha"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </ProtectedPage>
  );
}
