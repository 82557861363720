import {
  Box,
  Divider,
  HStack,
  Skeleton,
  VStack,
} from "@chakra-ui/react";

export default function SkeletonPostList({ length }) {
  if (!length) {
    length = 20;
  }
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <VStack width="100%" spacing="0px">
        <Divider backgroundColor="black" height="1px" />
        {[...Array(length).keys()].map((i) => (
          <VStack key={i} width="100%" spacing="0px">
            <HStack width="100%">
              <Skeleton width="64px" height="64px" />
              <Box width="80%">
                <VStack
                  width="100%"
                  spacing="10px"
                  display="block"
                  alignItems="center"
                >
                  <Skeleton width="50%" height="18px" />
                  <Skeleton width="70%" height="14px" />
                </VStack>
              </Box>
            </HStack>
            {i === length - 1 ? (
              <Divider backgroundColor="black" height="1px" />
            ) : (
              <Divider />
            )}
          </VStack>
        ))}
      </VStack>
    </Box>
  );
}
