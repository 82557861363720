import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { BiWon } from "react-icons/bi";
import {
  getSettlementsInProgress,
  getTotalApprovedSettlements,
  getTotalEarnings,
  requestSettlement,
} from "../api";
import { seperateNumber } from "../lib/functions";

export default function EarningsSettlement({ hasAccount }) {
  const queryClient = useQueryClient();

  const [availableAmount, setAvailableAmount] = useState(0);
  const [requestedAmount, setRequestedAmount] = useState("");
  const [isDigit, setIsDigit] = useState(true);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isThousands, setIsThousands] = useState(false);
  const { isLoading: isTotalEarningsLoading, data: totalEarnings } = useQuery(
    ["totalEarnings"],
    getTotalEarnings
  );
  const {
    isLoading: isTotalApprovedSettlementsLoading,
    data: totalApprovedSettlements,
  } = useQuery(["totalApprovedSettlements"], getTotalApprovedSettlements);
  const {
    isLoading: isSettlementsInProgressLoading,
    data: settlementsInProgress,
  } = useQuery(["settlementsInProgress"], getSettlementsInProgress);
  useEffect(() => {
    if (
      !isTotalEarningsLoading &&
      !isTotalApprovedSettlementsLoading &&
      !isSettlementsInProgressLoading
    ) {
      setAvailableAmount(
        totalEarnings.amount -
          totalApprovedSettlements.amount -
          settlementsInProgress.amount
      );
    }
  }, [
    isTotalEarningsLoading,
    isTotalApprovedSettlementsLoading,
    isSettlementsInProgressLoading,
    totalEarnings,
    totalApprovedSettlements,
    settlementsInProgress,
  ]);
  useEffect(() => {
    const re = /^[1-9][0-9]*$/;
    const passed = re.test(requestedAmount);
    setIsDigit(passed);
    const amount = parseInt(requestedAmount);
    setIsAvailable(passed && amount <= availableAmount);
    setIsThousands(passed && amount % 1000 === 0);
  }, [availableAmount, requestedAmount]);
  const requestSettlementMutation = useMutation(requestSettlement, {
    onSuccess: () => {
      setRequestedAmount("");
      queryClient.refetchQueries(["settlementsInProgress"]);
    },
  });

  return (
    <>
      {!isTotalEarningsLoading &&
      !isTotalApprovedSettlementsLoading &&
      !isSettlementsInProgressLoading ? (
        <VStack maxWidth="390px" width="90%" marginTop="10px" spacing="15px">
          <Divider backgroundColor="black" height="1px" />
          <VStack
            width="100%"
            display="flex"
            justifyContent="left"
            spacing="20px"
          >
            <HStack width="100%" height="40px">
              <Box
                width="180px"
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Text fontSize="sm" as="b">
                  총 수익
                </Text>
              </Box>
              <Box
                width="100%"
                height="full"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                justifyContent="left"
                alignItems="center"
                paddingX="18px"
              >
                <HStack width="100%" height="full">
                  <BiWon />
                  <Text>{seperateNumber(totalEarnings.amount)}</Text>
                </HStack>
              </Box>
            </HStack>
            <HStack width="100%" height="40px">
              <Box
                width="180px"
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Text fontSize="sm" as="b">
                  정산 완료
                </Text>
              </Box>
              <Box
                width="100%"
                height="full"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                justifyContent="left"
                alignItems="center"
                paddingX="18px"
              >
                <HStack width="100%" height="full">
                  <BiWon />
                  <Text>{seperateNumber(totalApprovedSettlements.amount)}</Text>
                </HStack>
              </Box>
            </HStack>
            <HStack width="100%" height="40px">
              <Box
                width="180px"
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Text fontSize="sm" as="b">
                  정산 중
                </Text>
              </Box>
              <Box
                width="100%"
                height="full"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                justifyContent="left"
                alignItems="center"
                paddingX="18px"
              >
                <HStack width="100%" height="full">
                  <BiWon />
                  <Text>{seperateNumber(settlementsInProgress.amount)}</Text>
                </HStack>
              </Box>
            </HStack>
            <HStack width="100%" height="40px">
              <Box
                width="180px"
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Text fontSize="sm" as="b">
                  정산 가능 금액
                </Text>
              </Box>
              <Box
                width="100%"
                height="full"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                justifyContent="left"
                alignItems="center"
                paddingX="18px"
              >
                <HStack width="100%" height="full">
                  <BiWon />
                  <Text>
                    {seperateNumber(
                      totalEarnings.amount -
                        totalApprovedSettlements.amount -
                        settlementsInProgress.amount
                    )}
                  </Text>
                </HStack>
              </Box>
            </HStack>
            <FormControl>
              <HStack>
                <Box
                  width="180px"
                  display="flex"
                  justifyContent="left"
                  alignItems="center"
                >
                  <Text fontSize="sm" as="b">
                    정산 신청 금액
                  </Text>
                </Box>
                <Input
                  width="100%"
                  maxLength="20"
                  required
                  type="text"
                  placeholder={
                    hasAccount
                      ? "금액을 입력하세요."
                      : "계좌 정보를 먼저 등록해주세요."
                  }
                  isDisabled={!hasAccount}
                  value={requestedAmount}
                  onChange={(event) => {
                    setRequestedAmount(event.target.value);
                  }}
                />
              </HStack>
              <FormHelperText>
                <Text width="100%" textAlign="left">
                  {requestedAmount
                    ? !isDigit
                      ? "숫자만 입력해주세요."
                      : !isAvailable
                      ? "정산 가능 금액보다 많이 신청할 수 없습니다."
                      : !isThousands
                      ? "정산은 1000원 단위로만 신청 가능합니다."
                      : null
                    : null}
                </Text>
              </FormHelperText>
            </FormControl>
          </VStack>
          <Divider backgroundColor="black" height="1px" />
          <Button
            width="68px"
            height="40px"
            colorScheme="blue"
            isDisabled={!hasAccount || !isDigit || !isAvailable || !isThousands}
            isLoading={requestSettlementMutation.isLoading}
            onClick={() => {
              const int = parseInt(requestedAmount);
              if (int) {
                requestSettlementMutation.mutate(int);
              }
            }}
          >
            신청
          </Button>
        </VStack>
      ) : null}
    </>
  );
}
