import { Avatar, HStack, Link, Text } from "@chakra-ui/react";

export default function UserProfile({ user, fontSize, maxWidth }) {
  const { nickname, avatar } = user;
  const style = maxWidth
    ? {
        maxWidth,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }
    : null;
  return (
    <Link href={`/users/${nickname}`}>
      <HStack>
        <Avatar
          name={nickname}
          size="sm"
          src={avatar}
          backgroundColor="gray"
          textColor="white"
        />
        <Text {...style} fontSize={fontSize}>
          {nickname}
        </Text>
      </HStack>
    </Link>
  );
}
