import { Link, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getSubscriptionPosts } from "../api";
import usePage from "../lib/usePage";
import PageIndex from "./PageIndex";
import PostList from "./PostList";
import ProtectedButton from "./ProtectedButton";
import SkeletonPostList from "./SkeletonPostList";

export default function SubscriptionPosts() {
  const page = usePage();
  const { isLoading, data } = useQuery(
    ["subscriptionsPosts", page],
    getSubscriptionPosts
  );
  return (
    <VStack width="100%" spacing="10px">
      <VStack width="100%">
        <ProtectedButton>
          <Link href="/posts/subscriptions">
            <Text fontSize="2xl" as="b">
              구독 게시물
            </Text>
          </Link>
        </ProtectedButton>
        {!isLoading ? (
          <PostList posts={data} path="/posts/subscriptions" />
        ) : (
          <SkeletonPostList />
        )}
      </VStack>
      <PageIndex />
    </VStack>
  );
}
