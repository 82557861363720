import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUploadURL,
  updateMyInfo,
  uploadImage,
  validateNickname,
} from "../api";
import ProtectedPage from "../components/ProtectedPage";
import useMe from "../lib/useMe";

export default function EditMyInfo() {
  const { isMeLoading, me } = useMe();
  const [username, setUsername] = useState("");
  const [originalNickname, setOriginalNickname] = useState("");
  const [nickname, setNickname] = useState("");
  const [avatar, setAvatar] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [avatarChanged, setAvatarChanged] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [emailLocal, setEmailLocal] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [email, setEmail] = useState("");
  const [isNicknameValid, setIsNicknameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [nicknameMessage, setNicknameMessage] = useState("");
  const [checkPasswordMessage, setCheckPasswordMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  useEffect(() => {
    if (!isMeLoading) {
      setUsername(me?.username);
      setOriginalNickname(me?.nickname);
      setNickname(me?.nickname);
      setAvatar(me?.avatar);
      if (me?.email) {
        setOriginalEmail(me.email);
        const [originalEmailLocal, originalEmailDomail] = me.email.split("@");
        setEmailLocal(originalEmailLocal);
        setEmailDomain(originalEmailDomail);
      }
    }
  }, [isMeLoading, me]);
  useEffect(() => {
    setNicknameMessage(
      nickname === originalNickname
        ? ""
        : nickname
        ? isNicknameValid !== null
          ? isNicknameValid
            ? "사용 가능한 닉네임입니다."
            : "이미 존재하는 닉네임입니다."
          : "중복 확인을 해주세요."
        : "닉네임을 입력하세요."
    );
  }, [nickname, originalNickname, isNicknameValid]);
  useEffect(() => {
    setIsPasswordValid(password === checkPassword);
    setCheckPasswordMessage(
      !password && !checkPassword
        ? ""
        : !checkPassword
        ? "비밀번호를 다시 입력하세요."
        : password === checkPassword
        ? "비밀번호가 일치합니다."
        : "비밀번호가 일치하지 않습니다."
    );
  }, [password, checkPassword]);
  const onValidateNickname = ({ nickname }) => {
    validateNickname({ nickname }).then((data) => {
      setIsNicknameValid(data.isValid);
    });
  };
  useEffect(() => {
    if (imageFile) {
      setImageURL(URL.createObjectURL(imageFile));
    } else {
      setImageURL("");
    }
  }, [imageFile]);
  useEffect(() => {
    const emailLocalRE = /^[a-zA-Z0-9+\-_.]+$/;
    const emailDomainRE = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailLocal && !emailDomain) {
      setEmail("");
      setIsEmailValid(true);
      setEmailMessage("");
    } else if (
      !emailLocalRE.test(emailLocal) ||
      !emailDomainRE.test(emailDomain)
    ) {
      setEmail("");
      setIsEmailValid(false);
      setEmailMessage("잘못된 이메일 형식입니다.");
    } else {
      setEmail(`${emailLocal}@${emailDomain}`);
      setIsEmailValid(true);
      setEmailMessage("");
    }
  }, [emailLocal, emailDomain]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateMyInfoMutation = useMutation(updateMyInfo, {
    onSuccess: () => {
      queryClient.refetchQueries(["me"]);
      navigate("/");
      window.scrollTo(0, 0);
    },
  });
  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }) => {
      const data = {
        avatar: `https://imagedelivery.net/_BmacleTPBEuXicOq-cILA/${result.id}/crop`,
        ...(nickname !== originalNickname && { nickname }),
        ...(password && { password }),
        ...(email !== originalEmail && { email }),
      };
      updateMyInfoMutation.mutate(data);
    },
  });
  const getUploadURLMutation = useMutation(getUploadURL, {
    onSuccess: ({ uploadURL }) => {
      uploadImageMutation.mutate({
        file: imageFile,
        uploadURL,
      });
    },
  });
  const onSubmit = () => {
    if (imageFile) {
      getUploadURLMutation.mutate();
    } else {
      const data = {
        avatar,
        ...(nickname !== originalNickname && { nickname }),
        ...(password && { password }),
        ...(email !== originalEmail && { email }),
      };
      updateMyInfoMutation.mutate(data);
    }
  };
  return (
    <>
      {!isMeLoading ? (
        <ProtectedPage>
          <VStack maxWidth="480px" width="90%" marginY="30px" spacing="20px">
            <VStack width="100%">
              <Text fontSize="2xl" as="b">
                개인정보 수정
              </Text>
              <Divider backgroundColor="black" height="1px" />
            </VStack>
            <VStack
              width="100%"
              display="flex"
              justifyContent="left"
              spacing="20px"
            >
              <HStack width="100%" height="40px" spacing="0">
                <Box
                  width="35%"
                  display="flex"
                  justifyContent="left"
                  alignItems="center"
                >
                  <Text as="b" fontSize="sm">
                    ID
                  </Text>
                </Box>
                <Box
                  width="65%"
                  height="full"
                  borderWidth="1px"
                  borderRadius="md"
                  display="flex"
                  justifyContent="left"
                  alignItems="center"
                  paddingX="18px"
                >
                  <Text>{username}</Text>
                </Box>
              </HStack>
              <FormControl>
                <HStack width="100%" spacing="0">
                  <Box
                    width="35%"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text as="b" fontSize="sm">
                      닉네임
                    </Text>
                  </Box>
                  <HStack width="65%" justifyContent="left">
                    <Input
                      width="100%"
                      maxLength="20"
                      required
                      type="text"
                      onChange={(event) => {
                        const value = event.target.value;
                        setNickname(value);
                        setIsNicknameValid(
                          value === originalNickname ? true : null
                        );
                      }}
                      value={nickname || ""}
                    />
                    {nickname && nickname !== originalNickname ? (
                      <Button
                        width="126px"
                        height="40px"
                        colorScheme={
                          isNicknameValid === false ? "blackAlpha" : "blue"
                        }
                        isDisabled={!nickname || isNicknameValid !== null}
                        onClick={() => {
                          onValidateNickname({ nickname });
                        }}
                      >
                        {isNicknameValid !== null
                          ? isNicknameValid
                            ? "사용 가능"
                            : "사용 불가"
                          : "중복 확인"}
                      </Button>
                    ) : null}
                  </HStack>
                </HStack>
                <FormHelperText>{nicknameMessage}</FormHelperText>
              </FormControl>
              <FormControl>
                <HStack width="100%" spacing="0">
                  <Box
                    width="35%"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text as="b" fontSize="sm">
                      프로필 이미지
                    </Text>
                  </Box>
                  <Input
                    id="selectImage"
                    width="0"
                    visibility="hidden"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setImageFile(event.target.files[0]);
                      setAvatarChanged(true);
                    }}
                  />
                  <HStack width="65%" display="flex" spacing="10px">
                    <Avatar
                      name={nickname}
                      size="md"
                      src={imageURL || avatar}
                      backgroundColor="gray"
                      textColor="white"
                    />
                    <Button
                      width="60px"
                      height="36px"
                      colorScheme="blue"
                      onClick={() => {
                        document.getElementById("selectImage").click();
                      }}
                    >
                      <Text fontSize="sm">업로드</Text>
                    </Button>
                    <Button
                      width="48px"
                      height="36px"
                      colorScheme="blackAlpha"
                      isDisabled={!imageURL && !avatar}
                      onClick={() => {
                        setAvatar("");
                        setImageFile(null);
                        setAvatarChanged(true);
                      }}
                    >
                      <Text fontSize="sm">삭제</Text>
                    </Button>
                  </HStack>
                </HStack>
              </FormControl>
              <FormControl>
                <HStack width="100%" spacing="0">
                  <Box
                    width="35%"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text as="b" fontSize="sm">
                      비밀번호
                    </Text>
                  </Box>
                  <Input
                    width="65%"
                    type="password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </HStack>
              </FormControl>
              <FormControl>
                <HStack width="100%" spacing="0">
                  <Box
                    width="35%"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text as="b" fontSize="sm">
                      비밀번호 확인
                    </Text>
                  </Box>
                  <Input
                    width="65%"
                    type="password"
                    onChange={(event) => {
                      setCheckPassword(event.target.value);
                    }}
                  />
                </HStack>
                <FormHelperText>{checkPasswordMessage}</FormHelperText>
              </FormControl>
              <Divider backgroundColor="blackAlpha.300" height="1px" />
              <FormControl>
                <HStack>
                  <Box
                    width="140px"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Text as="b" fontSize="sm">
                      이메일
                    </Text>
                  </Box>
                  <HStack>
                    <Input
                      width="100%"
                      id="emailLocal"
                      type="text"
                      onChange={(event) => {
                        setEmailLocal(event.target.value);
                      }}
                      value={emailLocal || ""}
                    />
                    <Text>@</Text>
                    <Input
                      width="100%"
                      id="emailDomain"
                      type="text"
                      onChange={(event) => {
                        setEmailDomain(event.target.value);
                      }}
                      value={emailDomain || ""}
                    />
                  </HStack>
                </HStack>
                <FormHelperText>{emailMessage}</FormHelperText>
              </FormControl>
            </VStack>
            <Divider backgroundColor="black" height="1px" />
            <HStack spacing="20px">
              <Button
                width="68px"
                height="40px"
                colorScheme="blue"
                isDisabled={
                  (nickname === originalNickname &&
                    !avatarChanged &&
                    !password &&
                    email === originalEmail) ||
                  (nickname !== originalNickname && !isNicknameValid) ||
                  !isPasswordValid ||
                  !isEmailValid
                }
                isLoading={
                  getUploadURLMutation.isLoading ||
                  uploadImageMutation.isLoading ||
                  updateMyInfoMutation.isLoading
                }
                onClick={onSubmit}
              >
                수정
              </Button>
              <Button
                width="68px"
                height="40px"
                colorScheme="blackAlpha"
                isDisabled={
                  getUploadURLMutation.isLoading ||
                  uploadImageMutation.isLoading ||
                  updateMyInfoMutation.isLoading
                }
                onClick={() => {
                  navigate(-1);
                }}
              >
                취소
              </Button>
            </HStack>
          </VStack>
        </ProtectedPage>
      ) : null}
    </>
  );
}
