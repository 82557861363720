import { HStack, Link, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPageArray } from "../lib/functions";
import usePage from "../lib/usePage";

export default function PageIndex({ preventScroll }) {
  const page = usePage();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageArray = getPageArray(page);
  const prevPage = 10 * Math.floor(page / 10) + 1 - 10;
  const nextPage = 10 * Math.floor(page / 10) + 1 + 10;

  const route = pathname.split("/");
  const lastRoute = route[route.length - 1];
  const isPostPage = !isNaN(parseInt(lastRoute));
  return (
    <HStack>
      {page > 10 ? (
        <Link
          onClick={() => {
            navigate(`?page=${prevPage}`);
            if (!preventScroll && !isPostPage) {
              window.scrollTo(0, 0);
            }
          }}
        >
          ◀
        </Link>
      ) : null}
      {pageArray.map((num) => (
        <Link
          key={num}
          onClick={() => {
            navigate(`?page=${num}`);
            if (!preventScroll && !isPostPage) {
              window.scrollTo(0, 0);
            }
          }}
        >
          <Text as={num === page ? "b" : null}>{num}</Text>
        </Link>
      ))}
      <Link
        onClick={() => {
          navigate(`?page=${nextPage}`);
          if (!preventScroll && !isPostPage) {
            window.scrollTo(0, 0);
          }
        }}
      >
        ▶
      </Link>
    </HStack>
  );
}
