import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaUser, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { login } from "../api";

export default function LoginModal({ isOpen, onClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState("");
  const loginMutation = useMutation(login, {
    onSuccess: () => {
      onClose();
      queryClient.refetchQueries(["me"]);
      reset();
    },
    onError: ({ response }) => {
      setErrorMessage(response?.data?.detail);
    },
  });
  const onSubmit = ({ username, password }) => {
    loginMutation.mutate({ username, password });
  };
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="90%">
        <ModalHeader>로그인</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          paddingBottom="20px"
        >
          <VStack marginBottom="10px">
            <InputGroup>
              <InputLeftElement
                children={
                  <Box>
                    <FaUser />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                  required: "ID를 입력하세요.",
                })}
                name="username"
                variant="outline"
                placeholder="ID"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box>
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "비밀번호를 입력하세요.",
                })}
                name="password"
                type="password"
                variant="outline"
                placeholder="비밀번호"
              />
            </InputGroup>
          </VStack>
          {loginMutation.isError ? (
            <Text textAlign="center" fontSize="sm">
              {errorMessage}
            </Text>
          ) : null}
          <Button
            isLoading={loginMutation.isLoading}
            type="submit"
            width="100%"
            marginTop={2}
            colorScheme="blue"
          >
            로그인
          </Button>
          <Button
            width="100%"
            marginTop={2}
            colorScheme="blackAlpha"
            onClick={() => {
              onClose();
              navigate("sign-up/");
            }}
          >
            회원가입
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
