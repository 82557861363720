import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateUsername, validateNickname, signUp } from "../api";

export default function SignUp() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [emailLocal, setEmailLocal] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [email, setEmail] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState(null);
  const [isNicknameValid, setIsNicknameValid] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [usernameMessage, setUsernameMessage] = useState("");
  const [nicknameMessage, setNicknameMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [checkPasswordMessage, setCheckPasswordMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const onValidateUsername = ({ username }) => {
    validateUsername({ username }).then((data) => {
      setIsUsernameValid(data?.isValid);
    });
  };
  const onValidateNickname = ({ nickname }) => {
    validateNickname({ nickname }).then((data) => {
      setIsNicknameValid(data?.isValid || false);
    });
  };
  useEffect(() => {
    setUsernameMessage(
      !username
        ? "ID를 입력하세요."
        : isUsernameValid !== null
        ? isUsernameValid
          ? "사용 가능한 ID입니다."
          : "이미 존재하는 ID입니다."
        : "중복 확인을 해주세요."
    );
  }, [username, isUsernameValid]);
  useEffect(() => {
    setNicknameMessage(
      !nickname
        ? "닉네임을 입력하세요."
        : isNicknameValid !== null
        ? isNicknameValid
          ? "사용 가능한 닉네임입니다."
          : "이미 존재하는 닉네임입니다."
        : "중복 확인을 해주세요."
    );
  }, [nickname, isNicknameValid]);
  useEffect(() => {
    setPasswordMessage(!password ? "비밀번호를 입력하세요." : "");
  }, [password]);
  useEffect(() => {
    setIsPasswordValid(password && password === checkPassword);
    setCheckPasswordMessage(
      !password
        ? ""
        : !checkPassword
        ? "비밀번호를 다시 입력하세요."
        : password === checkPassword
        ? "비밀번호가 일치합니다."
        : "비밀번호가 일치하지 않습니다."
    );
  }, [password, checkPassword]);
  useEffect(() => {
    const emailLocalRE = /^[a-zA-Z0-9+\-_.]+$/;
    const emailDomainRE = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailLocal && !emailDomain) {
      setEmail("");
      setIsEmailValid(true);
      setEmailMessage("이메일은 필수가 아니며, 본인 확인용으로만 사용됩니다.");
    } else if (
      !emailLocalRE.test(emailLocal) ||
      !emailDomainRE.test(emailDomain)
    ) {
      setEmail("");
      setIsEmailValid(false);
      setEmailMessage("잘못된 이메일 형식입니다.");
    } else {
      setEmail(`${emailLocal}@${emailDomain}`);
      setIsEmailValid(true);
      setEmailMessage("");
    }
  }, [emailLocal, emailDomain]);
  const signUpMutation = useMutation(signUp, {
    onSuccess: () => {
      navigate("/");
      window.scrollTo(0, 0);
    },
  });
  const onSubmit = () => {
    signUpMutation.mutate({ username, nickname, password, email });
  };
  return (
    <VStack maxWidth="480px" width="90%" marginY="30px" spacing="20px">
      <VStack width="100%">
        <Text fontSize="2xl" as="b">
          회원가입
        </Text>
        <Divider backgroundColor="black" height="1px" />
      </VStack>
      <VStack width="100%" display="flex" justifyContent="left" spacing="20px">
        <FormControl>
          <HStack>
            <Box
              width="40%"
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <Text as="b" fontSize="sm">
                ID
              </Text>
            </Box>
            <HStack width="100%" justifyContent="left">
              <Input
                maxLength="20"
                required
                type="text"
                onChange={(event) => {
                  setUsername(event.target.value);
                  setIsUsernameValid(null);
                }}
              />
              <Button
                width="126px"
                height="40px"
                colorScheme={isUsernameValid === false ? "blackAlpha" : "blue"}
                isDisabled={!username || isUsernameValid !== null}
                onClick={() => {
                  onValidateUsername({ username });
                }}
              >
                {isUsernameValid !== null
                  ? isUsernameValid
                    ? "사용 가능"
                    : "사용 불가"
                  : "중복 확인"}
              </Button>
            </HStack>
          </HStack>
          <FormHelperText>{usernameMessage}</FormHelperText>
        </FormControl>
        <FormControl>
          <HStack>
            <Box
              width="140px"
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <Text as="b" fontSize="sm">
                닉네임
              </Text>
            </Box>
            <HStack width="100%" justifyContent="left">
              <Input
                maxLength="20"
                required
                type="text"
                onChange={(event) => {
                  setNickname(event.target.value);
                  setIsNicknameValid(null);
                }}
              />
              <Button
                width="126px"
                height="40px"
                colorScheme={isNicknameValid === false ? "blackAlpha" : "blue"}
                isDisabled={!nickname || isNicknameValid !== null}
                onClick={() => {
                  onValidateNickname({ nickname });
                }}
              >
                {isNicknameValid !== null
                  ? isNicknameValid
                    ? "사용 가능"
                    : "사용 불가"
                  : "중복 확인"}
              </Button>
            </HStack>
          </HStack>
          <FormHelperText>{nicknameMessage}</FormHelperText>
        </FormControl>
        <FormControl>
          <HStack>
            <Box
              width="140px"
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <Text as="b" fontSize="sm">
                비밀번호
              </Text>
            </Box>
            <Input
              minLength="8"
              required
              type="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </HStack>
          <FormHelperText>{passwordMessage}</FormHelperText>
        </FormControl>
        <FormControl>
          <HStack>
            <Box
              width="140px"
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <Text as="b" fontSize="sm">
                비밀번호 확인
              </Text>
            </Box>
            <Input
              required
              type="password"
              onChange={(event) => {
                setCheckPassword(event.target.value);
              }}
            />
          </HStack>
          <FormHelperText>{checkPasswordMessage}</FormHelperText>
        </FormControl>
        <Divider backgroundColor="blackAlpha.300" height="1px" />
        <FormControl>
          <HStack>
            <Box
              width="140px"
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <Text as="b" fontSize="sm">
                이메일
              </Text>
            </Box>
            <HStack>
              <Input
                id="emailLocal"
                type="text"
                onChange={(event) => {
                  setEmailLocal(event.target.value);
                }}
              />
              <Text>@</Text>
              <Input
                id="emailDomain"
                type="text"
                onChange={(event) => {
                  setEmailDomain(event.target.value);
                }}
              />
            </HStack>
          </HStack>
          <FormHelperText>{emailMessage}</FormHelperText>
        </FormControl>
      </VStack>
      <Divider backgroundColor="black" height="1px" />
      <Button
        width="108px"
        height="40px"
        colorScheme="blue"
        isDisabled={
          !isUsernameValid ||
          !isNicknameValid ||
          !isPasswordValid ||
          !isEmailValid
        }
        isLoading={signUpMutation.isLoading}
        onClick={onSubmit}
      >
        회원가입
      </Button>
    </VStack>
  );
}
